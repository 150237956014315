import React from 'react'
import Layout from '../Layout'
import View from '../View'
import { getEmailTemplates, deleteEmailTemplate } from '../../utils/email'
import { Table } from '../Table'
import  Moment from 'moment'
import { Link } from 'gatsby'
import WaitLoader from '../WaitLoader'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'

class EmailTemplates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: [],
      stats: {},
      errorMessage: '',
      loading: true,
      template: null,
      confirmDeleteOpen: false
    }
  }

  componentDidMount() {
    this.getTemplates()
  }

  getTemplates(){
    getEmailTemplates(function(err, response){
      if(err){
        console.log(err)
      }
      if(response){
        if(response.templates.errorMessage){
          this.setState({
            errorMessage: response.templates.errorMessage,
            loading: false,
            confirmDeleteOpen: false
          })
        }else{
          response.templates.forEach((template, i) => {
            template.link = <Link to={'/email/template/'+template._id}>{template.subject}</Link>
          });

          this.setState({
            templates: response.templates,
            stats: response.stats,
            loading: false,
            confirmDeleteOpen: false
          })
        }
      }
    }.bind(this))
  }

  confirmDeleteTemplate(templateId){
    console.log(templateId)
    var template = this.state.templates.find(function(item){
      return item._id === templateId
    })
    console.log(template)
    if(template){
      this.setState({
        confirmDeleteOpen: true,
        template: template
      })
    }
  }


  onDelete = () => {
    this.setState({
      loading: true,
      confirmDeleteOpen: false
    }, function(){
      deleteEmailTemplate({templateid: this.state.template._id}, function(err, data){
        console.log(err)
        console.log(data)
        this.getTemplates()
      }.bind(this))
    }.bind(this))
  }


  render() {
    const logColumns = [
      {
        id: 'subject',
        Header: 'Subject',
        accessor: d => d.link,
        Cell: props => props.value
      },
      {
        id: 'created',
        Header: 'Created',
        accessor: d => {
          return d.created ? Moment(d.created)
          .local()
          .format("MM-DD-YYYY hh:mm:ss a")
          : ''
        }
      },
      {
        id: 'updated',
        Header: 'Updated',
        accessor: d => {
          return d.updated ? Moment(d.updated)
          .local()
          .format("MM-DD-YYYY hh:mm:ss a")
          : ''
        }
      },
      {
        id: 'sendemail',
        Header: 'Actions',
        accessor: d => d._id,
        Cell: props => <div>
          <div>
            <Link className="button" to={'/email/campaign?template=' + props.value}>
            Send as Campaign
            </Link>
          </div>
          <div>
            <Link className="button" to={'/email/test?template=' + props.value}>
            Send as Email
            </Link>
          </div>
          <div>
            <Link className="button" to={'/email/template/edit/' + props.value}>
            Edit Template
            </Link>
          </div>
          <div>
            <button onClick={() => this.confirmDeleteTemplate(props.value)}>
            Delete Template
            </button>
          </div>
        </div>
      }
    ]
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    return (
      <Layout>
      <View title="EMAIL TEMPLATES">
      <Link className="button newListButton"
      to='/email/template/edit'>
      New Template
      </Link>
      <div className="row col-xs-12">
      <p>Manage your email templates, starting with clicking on 'New Template' to create a new one. Once saved, your template will appear in the list below with the following actions: 'Send As email', 'Send As Campaign', 'Edit Template', and 'Delete Template.'</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12">
          <WaitLoader loading={this.state.loading} />
          {errorMessage}
          <Table
            data={this.state.templates}
            columns={logColumns}
            defaultPageSize={10}
            minRows={3}
          />
        </div>
      </div>
      </View>
      <Dialog
        className="standard-dialog"
        open={this.state.confirmDeleteOpen}
        onClose={() => this.setState({ confirmDeleteOpen: false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Delete Template: {this.state.template?.subject}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this template? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="underline-button" onClick={() => this.setState({ confirmDeleteOpen: false})}>Cancel</Button>
          <Button variant="contained" onClick={this.onDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      </Layout>
    )
  }
}

export default EmailTemplates
